<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Get a higher financial altitude!</h1>
        <h4>Start your financial freedom to well-being. A smarter way to borrow through your smartphones</h4>
        <!-- <div class="item_progress">
          <p>75.0000$</p>
          <img src="@/assets/images/loanImg/icon_progress.png" alt="">
        </div> -->
        <div class="item_info">
          <!-- <img class="info_img1" src="@/assets/images/loanImg/icon_info.png" alt=""> -->
          <div class="info_tips">
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">No Collateral</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">Safe and Secure loan</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">Convenient and Hassle-free</p>
          </div>
          <!-- <img class="info_img2" src="@/assets/images/loanImg/icon_info2.png" alt=""> -->
        </div>
        <!-- <h5>Descargue la APP para comprobar rápidamente su cuota, Click</h5> -->
        <div class="item_download" @click="downloadApp()">
          <!-- <img src="@/assets/images/loanImg/icon_download.png" alt=""> -->
          <!-- <p>Descargar APP</p> -->
          <!-- <a href="">
            <img src="@/assets/images/loanImg/download_now.png" alt="">
          </a> -->
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/loanImg/top_app.png" alt="">
      </div>
    </div>
    <div class="inner inner1_5 flex">
      <div class="center1">
        <p>Loan Amount</p>
        <h1>Rs. 25,000</h1>
        <img src="~@/assets/images/inner_1.5_left.png" alt="">
        <div>
          <div class="flex jc-sb"><p>min</p><p>MAX</p></div>
          <div class="flex jc-sb"><p>Rs.3,000</p><p>Rs. 25,000</p></div>
        </div>
      </div>
      <img class="center2" src="~@/assets/images/inner_1.5.png" alt="">
      <div class="center3 flex fd-c jc-sb">
        <p><img src="~@/assets/images/icon_item_tick.png" alt="">Repayment Term <span>0-90 days</span></p>
        <p><img src="~@/assets/images/icon_item_tick.png" alt="">Loan approval time: <span>1-5 minutes</span></p>
        <h2 class="flex ai-c jc-c" @click="downloadApp()">Apply now! <img src="~@/assets/images/white_right.png" alt=""></h2>
      </div>
    </div>
    <div class="inner inner2">
      <div class="left_item">
        <img src="@/assets/images/loanImg/icon_people.png" alt="">
      </div>
      <div class="right_item">
        <h1>What can CashBazar provide to you?</h1>
        <div class="item_info">
          <div class="info_tips">
            <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">24-hour loan service, you can apply any time.</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">No Collateral.</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">Variety of methods to receive money.</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-4.png" alt="">Flexible loan amount and duration.</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-5.png" alt="">Relatively low fees.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <h3>How to borrow money in CashBazar?</h3>
      <img src="~@/assets/images/loanImg/inner3.png">
      <div class="flex jc-sb">
        <p>Sign Up</p>
        <p>Fill In Info</p>
        <p>Wait For Review</p>
        <p>Select Amount And Duration</p>
        <p>Get Loans</p>
        <p>Repay</p>
      </div>
    </div>
    <div class="inner inner5">
      <div class="inner5-bg">
        <h3>How To Contact Us</h3>
      </div>
      <div class="inner5_item">
        <div class="content">
          <div class="left_item">
            <div class="item_info">
              <!-- <h4>Emiliano Rodríguez</h4> -->
              <p>House No.8，Street No.6，F-7/3,Islamabad，Pakistan</p>
            </div>
            <img src="@/assets/images/loanImg/home-inner5-1.png" alt="">
          </div>
          <!-- <div class="right_item">
            <div class="top">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
            </div>
            <div class="bottom">
              La empresa esta tardando en pagar mi salario
              y yo necesito dar el dinero de mi alquiler a
              tiempo, pero con CashBazar logré conseguir
              mi préstamo muy rápido. Me ayudo a solucionar
              mis urgencias de manera muy agradable.
            </div>
          </div> -->
        </div>
        <div class="content">
          <div class="left_item">
            <div class="item_info">
              <!-- <h4>Pablo Sanchez</h4> -->
              <p>cashbazar@outlook.com</p>
            </div>
            <img src="@/assets/images/loanImg/home-inner5-2.png" alt="">
          </div>
          <!-- <div class="right_item">
            <div class="top">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
            </div>
            <div class="bottom">
              Solicité un monto para pagar mi curso de
              gastronomía, pero como soy estudiante no
              me facilitaron el préstamo en muchos lugares.
              Pero CashBazar me ayudo con un préstamo
              de manera rápida y fueron muy pacientes.
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=cash.bazar.pk.credit', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1100px) / 2) 0;
      margin: 0 auto;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      height: 490px;
      .left_item{
        background: #fff;
        float: left;
        padding: 25px 25px 25px 0;
        border-radius: 10px;
        width: 500px;
        h1{
          font-size: 40px;
          color: #333333;
          font-weight: 700;
          margin-bottom: 20px;
          line-height: 50px;
        }
        h4{
          font-size: 16px;
          line-height: 22px;
          color: #333333;
          font-weight: normal;
          margin-bottom: 20px;
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
            // }
            // p:nth-child(1){
            //   padding-top: 8px;
            // }
            // :nth-child(2){
            //   align-items: center;
            // }
            // :nth-child(3){
            //   align-items:flex-end;
            //   padding-bottom: 8px;
            // }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          display: flex;
          margin-top: 20px;
          width: 160px;
          // background: black;
          color: white;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          img{
            width: 160px;
            height: 50px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 562px;
          position: relative;
          bottom: -3px;
        }
      }
    }
    .inner1_5{
      padding:50px calc((100% - 1100px) / 2);
      background: #007A3D;
      .center1,.center2,.center3{
        width: 33%;
      }
      .center1{
        padding-right: 80px;
        img{
          width: 100%;
        }
        color: white;
        h1{
          line-height: 100px;
          font-size: 55px;
          background: url('~@/assets/images/amount_bg.png') no-repeat center;
          background-size: 100% auto;
        }
        p{
          line-height: 20px;
        }
      }
      .center2{
        flex: 1;
        margin-bottom: 20px;
      }
      .center3{
        color: white;
        padding: 40px 30px 15px 60px;
        span{
          font-weight: bold;
        }
        p{
          img{
            width: 20px;
            vertical-align:middle;
            margin-right: 15px;
          }
          font-size: 15px;
          margin: 5px 0;
        }
        h2{
          width: 85%;
          background: #F6B436;
          line-height: 60px;
          border-radius: 10px;
          margin-top: 20px;
          font-size: 20px;
          img{
            width: 25px;
            margin-left: 20px;
          }
        }
      }
    }
    .inner2{
      height: 471px;
      background:#F4FAF4;
      display: flex;
      justify-content: space-between;
      .left_item{
        flex: 1.3;
        img{
          width: 420px;
          height: 420px;
        }
      }
      .right_item{
        flex: 1;
        margin-right:30px;
        h1{
          font-size: 38px;
          line-height: 47px;
        }
        p{
          margin-top: 20px;
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        .item_tips{
          display: flex;
          width: 460px;
          justify-content: space-between;
          p{
            width: 70px;
            font-size: 12px;
            color: #333333;
            text-align: center;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          .info_img1{
            width: 30px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            height: 350px;
            p{
              // flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .inner3{
      margin: 0 auto 70px;
      .flex{
        p{
          flex: 1;
          text-align: center;
          // padding-left:30px;
          font-weight: bold;
        }

      }
      h3{
        font-size: 35px;
        margin:50px 0;
        text-align: center;
      }
      img{
        display: block;
        width: 92%;
        margin: 0 auto;
      }
      .inner3_item{
        margin: 50px 0;
        display: flex;
        justify-content: space-between;
        .content{
          background: white;
          width: 280px;
          padding: 25px 20px 30px 20px;
          border-radius: 10px;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.1600);
          .top_item{
            display: flex;
            justify-content: space-between;
            // p{
            //   width: 88px;
            // }
            img{
              width: 40px;
              height: 30px;
            }
          }
          .line{
            width: 120px;
            height: 2px;
            background: #4485EC;
            margin: 15px 0;
          }
          .bottom_item{
            line-height: 18px;
            color: #666666;
          }
        }
      }
    }
    .inner4{
      position: relative;
      height: 364px;
      background:#F2FAFF ;
      display: flex;
      justify-content: space-between;
      .left_item{
        img{
          width: 280px;
          height: 300px;
        }
      }
      .right_item{
        margin-right:50px;
        p{
          margin: 40px 0;
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        img{
          position: absolute;
          width: 190px;
          height:220px;
          right: calc(15%);
          top: 0px;
        }
        .item_tips{
          background:#E0EEFE ;
          padding: 20px 10px;
          border-radius: 10px;
          width: 450px;
          line-height: 20px;
        }
      }
    }
    .inner5{
      padding: 0 0 50px 0;
      .inner5-bg{
        width: 100%;
        height: 250px;
        background: url("~@/assets/images/loanImg/inner5.png") no-repeat center;
        background-size: 100% 100%;
        padding: 50px 250px;
      }
      h3{
        font-size: 40px;
        color: white;
        float: right;
      }
      .inner5_item{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: -100px 0 0;
        padding: 0 250px;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
        .content{
          width: 300px;
          background: #fff;
          padding: 40px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 50px;
          box-shadow: 0px 5px 40px 1px rgba(116,116,116,0.16);
          p{
            line-height: 22px;
            font-weight: bold;
          }
          img{
            margin-top: 30px;
            width: 120px;
          }
        }
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0;
      height: 774px;
      position: relative;
      .left_item{
        margin: 0px 20px 20px;
        height: 500px;
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 350px !important;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .inner1_5{
      flex-direction: column;
      .center1,.center2,.center3{
        width: 90%;
        margin: 0 auto;
      }
      .center1{
        padding: 0;
      }
      .center3{
        padding: 20px;
      }
    }
    .inner2{
      width: calc(100vw);
      padding: 20px;
      height: 760px;
      flex-direction: column;
      justify-content: center;
      .left_item{
        display: flex;
        justify-content: center;
        img{
          width: 200px;
          height: 220px;
        }
        flex: 0;
        height: 220px !important;
        margin-bottom: 20px;
      }
      .right_item{
        flex: 0;
        margin: 0;
        width: calc(100vw - 40px);
        p{
          font-size: 18px;
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_tips{
          width: calc(100vw - 40px);
          p{
            font-size: 12px !important;
            width: 70px;
          }
        }
        .item_info{
          .info_tips{
            height: auto;
          }
        }
      }
    }
    .inner3{
      width: calc(100vw - 40px) !important;
      margin: 0 auto  30px !important;
      h3{
        font-size: 18px;
        padding: 0 20px;
      }
      img{
        width: 90%;
        margin: 0 auto;
      }
      .inner3_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
    .inner4{
      height: 600px;
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 200px;
          height: 220px;
        }
      }
      .right_item{
        width: calc(100vw - 40px) !important;
        margin: 0 auto !important;
        position: relative;
        .item_tips{
          width: 100%;
        }
        img{
          position: absolute;
          width: 100px;
          height: 140px;
          right: 0;
          top: -20px;
        }
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      h3{
        font-size: 18px;
      }
      .inner5-bg{
        padding: 20px  !important;
        height: 100px !important;
        font-size: 12px !important;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
